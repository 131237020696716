import React from "react";
import { Route } from "react-router-dom";
import Demo from "./Page/Demo";
import HomePage from "./Page/Homepage";


export default function Routes() {
  return (

    <Route
      exact
      path="/"
      render={(props) => <Demo></Demo>}
    />

  );
}
