import React from "react";
import "./Homepage.scss";
import './demo.css';
import Logo from "../logo.svg";
import Product from "../product.png";
import Whitelogo from "../whitelogo.svg";

export default function Demo() {
    return (
        <div style={{
            height: "100%"
        }}>
            <div className=""
                style={{
                    width: "100%",
                    padding: "10px 20px",
                    boxSizing: "border-box"
                }}
            >
                <div className="content_container">
                    <div className="c-logoBox">
                        <img src={Logo} alt="logo.svg" />
                    </div>
                </div>
            </div>

            <div className="c-home" >
                <div className="c-backGround">

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%"
                    }}>
                        <div className="content_container main_content">
                            <div
                                className="wording"

                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    color: "white"
                                }}>
                                <div className="text_main">
                                    <p>Pioneering the new era of payment gateway</p>
                                </div>
                                <div className="text_sub">
                                    <p style={{
                                        fontWeight: 200
                                    }}>Enable unbanked and underbanked to financial inclusion</p>
                                </div>

                                <div className="text_sub">
                                    <p>Coming Soon.</p>
                                </div>
                            </div>
                            <div className="image_container" style={{
                                marginLeft: "auto"
                            }}>
                                <img src={Product} alt="product.png" />
                            </div>
                        </div>
                    </div>
                    <div style={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        borderTop: "1px solid white",
                        paddingTop: 16,
                        display: "flex",
                        justifyContent: "center",
                        padding: "16px 0"
                    }}>

                        <div className="">
                            <img src={Whitelogo} alt="whitelogo.svg" />
                        </div>

                        <div className="">
                            <p
                                style={{
                                    margin: 0,
                                    color: "white"
                                }}
                            >© 2021 PAYRA Group Pte. Ltd. | All rights reserved</p>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}
